export let rus_lang = {
	lang_code: 'RUS',
	titles: {
		name: 'Название',
		name_en: 'Название на латинице',
		currency: 'Валюта',
		currency_kod: 'Код валюты',
		code: 'Код аэропорта',
		icao: 'ICAO',
		iata: 'IATA',
		contract: 'Контракт',
		city: 'Город',
		city_en: 'Город на латинице',
		country: 'Страна',
		country_en: 'Страна на латинице',
		timezone: 'Временная зона',
		arrival_price: 'Цена прилета',
		departure_price: 'Цена вылета',
		content: 'Описание',
		arrival_info: 'Описание прилета',
		departure_info: 'Описание вылета',
		cancel_info: 'Описание отмены',
		airport: 'Аэропорт',
		service: 'Услуга',
		type: 'Тип',
		direction: 'Направление',
		description: 'Описание',
		airport_object: 'Объект аэропорта'
	},
	buttons: {
		save: 'Сохранить',
		delete: 'Удалить',
		new_service: 'Новая услуга',
		new_airport: 'Новый аэропорт'
	},
	headers: {
		airport_select: 'Выбор аэропорта',
		info: 'Информация',
		actions: 'Действия',
		name_and_currency: 'Название и валюта',
		codes_and_contract: 'Коды и контракты',
		city_and_country: 'Город и страна',
		timezone_and_price: 'Временная зона и цены',
		arrival_services: 'Услуги прилета',
		departure_services: 'Услуги вылета',
		transit_services: 'Услуги транзита'
	},
	placeholders: {
		select_airport: 'Выберите аэропорт',
		select_service: 'Выберите услугу',
		select_direction: 'Выберите направление',
		select_type: 'Выберите тип',
	},
	alerts: {
		success: 'Успех!',
		failure: 'Провал!',
		error: 'Ошибка!',
		attention: 'Внимание!',
		success_save: 'Успешно сохранено',
		error_save: 'Произошла ошибка при сохранении',
		success_delete: 'Успешно удалено',
		error_delete: 'Произошла ошибка при удалении',
		too_big_file: 'Слишком большой файл',
		unsupported_format: 'Неподдерживаемый формат',
		success_upload: 'Успешно загружено',
		error_500: 'Серверная ошибка, вероятно, сервер перегружен',
		error_401: 'Необходимо авторизоваться'
	},
};