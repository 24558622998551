import { createWebHistory, createRouter } from "vue-router";

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import("@/components/cabinet/cabinetComponent"),
	},
	{
		path: '/airport',
		name: 'airport_home',
		component: () => import("@/components/cabinet/cabinetComponent"),
		props: {
			type: 'airport'
		}
	},
	{
		path: '/airport/:id',
		name: 'airport_item',
		component: () => import("@/components/cabinet/cabinetComponent"),
		props: route => ({
			type: 'airport',
			item: route.params.id
		})
	}
]
const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})
export default router